import { getSession } from 'next-auth/react';
import SignUp from 'templates/SignUp';

export default function Login() {
  return <SignUp />;
}

export async function getServerSideProps(context) {
  const session = await getSession(context);
  if (session) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  return {
    props: {
      session,
    },
  };
}

import Label from 'atoms/Label';
import React from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from 'utils/helper';
const InputLabel2 = ({
  inputId,
  isError = '',
  disable = false,
  labelTitle,
  type,
  name,
  placeholder,
  className = '',
  register = () => {},
  errors = '',
  classForInput,
  classForError,
  variant = 'small',
  ...inputProps
}) => {
  return (
    <div className={`relative ${className}`}>
      <Label
        labelFor={inputId}
        variant={variant}
        textColor={disable ? 'text-secondary-100' : 'text-black-800'}
        fontWeight="font-medium"
        labelDisable={disable}
        children={labelTitle}
      />
      <input
        type={type}
        id={inputId}
        {...(register && { ...register(name) })}
        className={`block border-b-2 py-0.5 2xl:py-1.5 w-full focus:border-primary-900 focus:placeholder-transparent font-medium text-sm xl:text-base placeholder:text-secondary-200 focus:outline-none ${
          disable ? 'text-secondary-100' : 'text-black-900'
        } ${classForInput} ${
          errors[name] ? 'border-error-100' : 'border-secondary-200'
        } ${disable && 'pointer-events-none'} `}
        placeholder={placeholder}
        name={name}
        autoComplete="off"
        {...inputProps}
      />
      {errors[name] && (
        <span
          className={`text-error-100 text-xxs select-none ${classForError}`}
        >
          {capitalizeFirstLetter(errors[name].message)}
        </span>
      )}
    </div>
  );
};
InputLabel2.propTypes = {
  inputId: PropTypes.string,
  disable: PropTypes.bool,
  labelTitle: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  register: PropTypes.func,
  classForInput: PropTypes.string,
};
export default InputLabel2;

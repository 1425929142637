import Alert, { alertType } from 'atoms/Alert';
import Divider from 'atoms/Divider';
import Heading from 'atoms/Heading';
import LinkIcon from 'atoms/LinkIcon';
import SignUpWith from 'atoms/SignUpWith';
import TextWithLinkIcon from 'molecules/TextWithLinkIcon';
import { signIn } from 'next-auth/react';
import SidebarWithTimeline from 'organisms/SidebarWithTimeline';
import SignInForm from 'organisms/SignInForm';
import { useState } from 'react';

const SignUp = () => {
  const [showVerification, setShowVerification] = useState(false);
  const [alertState, setAlertState] = useState({
    type: alertType.info,
    showAlert: false,
    alertMessage: 'something went wrong',
  });

  // Google Handler function
  async function handleGoogleSignin() {
    signIn('google', { callbackUrl: '/' });
  }

  // Microsoft Handler function
  async function handleMicrosoftSignin() {
    signIn('azure-ad-b2c', { callbackUrl: '/' }).catch((err) => {
      console.log('microsoft error', err);
    });
  }

  return (
    <div className="min-h-screen flex relative">
      <SidebarWithTimeline
        width="max-w-[50%]"
        showImage={true}
        type="forLogin"
        className="bg-primary-900"
      />
      <div className="bg-white w-full p-10 2xl:p-[60px] flex flex-col">
        <div className="flex justify-end">
          <TextWithLinkIcon
            href="https://www.getafto.com/faq"
            icon="arrowRight"
            size="16"
            text1="Lost or have trouble?"
            text2="Get Help"
            classForIcon="fill-primary-900 group-hover:fill-primary-800"
          />
        </div>
        <div className="h-full flex flex-col justify-center relative items-center">
          <div className="max-w-[70%] w-full">
            {!showVerification && (
              <div>
                <Heading
                  type="h1"
                  className="text-black-800 font-semibold"
                  children="Welcome to Afto"
                />
                <div className="flex gap-4 mt-4">
                  <SignUpWith
                    text="Continue with Google "
                    className=""
                    handleClick={handleGoogleSignin}
                    imgAlt="Google"
                    imgSrc="/images/Google.svg"
                  />
                  {/* <SignUpWith
                    text="Continue with Microsoft "
                    className=""
                    handleClick={handleMicrosoftSignin}
                    imgAlt="MIcrosoft"
                    imgSrc="/images/Microsoft.svg"
                  /> */}
                </div>
                <Divider className="my-8 2xl:my-[42px] mx-auto" text="OR" />
              </div>
            )}

            <SignInForm
              showVerification={showVerification}
              setShowVerification={setShowVerification}
              setAlertState={setAlertState}
            />
          </div>
          {alertState.showAlert && (
            <Alert
              variant={alertState.type || alertType.info}
              children={alertState.alertMessage || 'Something went wrong'}
              alertClass="w-full mb-4 2xl:mb-7 absolute bottom-0"
            />
          )}
        </div>
        <div
          className={`${
            showVerification ? 'flex justify-between' : 'self-end'
          } mt-4 md:mt-0`}
        >
          {showVerification && (
            <LinkIcon
              icon="arrowleft"
              variant="body"
              href=""
              size="16"
              children="Back"
              className="flex-row-reverse"
              classForIcon="fill-primary-900 group-hover:fill-primary-800"
              onClick={(e) => {
                e.preventDefault();
                setShowVerification(false);
                setAlertState({
                  showAlert: false,
                  alertMessage: '',
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;

import React from 'react';
import Text from 'atoms/Text';
import PropTypes from 'prop-types';
import LinkIcon from 'atoms/LinkIcon';
const TextWithLinkIcon = ({
  text1,
  text2,
  href,
  icon,
  color,
  size,
  onClick = () => {},
  className = '',
  classForIcon = 'fill-primary-900 group-hover:fill-primary-800',
}) => {
  return (
    <div className={`flex gap-2 ${className}`}>
      <Text
        variant="body"
        textColor="text-secondary-100"
        fontWeight="font-medium"
      >
        {text1}
      </Text>
      <LinkIcon
        href={href}
        icon={icon}
        color={color}
        size={size}
        classForIcon={classForIcon}
        onClick={onClick}
        variant="body"
      >
        {text2}
      </LinkIcon>
    </div>
  );
};

TextWithLinkIcon.propTypes = {
  className: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  href: PropTypes.any,
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  classForIcon: PropTypes.string,
};
export default TextWithLinkIcon;

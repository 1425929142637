import NextImage from 'next/image';
import Text from 'atoms/Text';
import React from 'react';

const CardIcon = ({ className = '', type = 'forLogin' }) => {
  return (
    <>
      {type === 'forOnboarding' && (
        <div
          className={`w-full shadow-cardIcon rounded relative bg-primary-700 select-none  py-5 px-4 2xl:py-6 ${className}`}
        >
          <Text
            variant="caption"
            fontWeight="font-normal"
            textColor="text-black-800"
            fontStyle="italic"
            className="!leading-[20px] 2xl:!leading-[24px]"
          >
            Biggest advantage of using Afto is the unparalleled data capture
            accuracy. With 95%+ STP rate, the extracted data is directly pushed
            into our database
          </Text>
          <div className="flex justify-between items-end mt-1 2xl:mt-2">
            <div>
              <Text
                variant="body"
                fontWeight="font-semibold"
                textColor="text-darkGrey-500"
              >
                Dan McClearly
              </Text>
              <Text
                variant="bodySmall"
                fontWeight="font-normal"
                textColor="text-secondary-400"
              >
                Head of Operations
              </Text>
            </div>
            <span className="relative w-[30px] h-[15px] 2xl:w-[41px] 2xl:h-[24px]">
              <NextImage src="/images/UP.svg" alt="up" layout="fill" />
            </span>
          </div>
          <span className="absolute top-0 left-0">
            <NextImage
              src="/images/Vector.svg"
              alt="vector"
              width="177"
              height="48.5"
            />
          </span>
          <div className="shadow-cardQuotes rounded-[3px] bg-primary-900 flex items-center justify-center w-9 h-9 absolute -top-4 -left-3">
            <span className="relative w-[17px] h-[12px]">
              <NextImage src="/images/Quotes.svg" alt="quotes" layout="fill" />
            </span>
          </div>
        </div>
      )}
      {type === 'forLogin' && (
        <div
          className={`w-full shadow-cardIcon rounded relative bg-white px-6 py-5 select-none 2xl:pl-9 2xl:pr-5 2xl:py-[30px]  ${className}`}
        >
          <Text
            variant="body"
            fontWeight="font-normal"
            textColor="text-secondary-400"
            fontStyle="italic"
          >
            Biggest advantage of using Afto is the unparalleled data capture
            accuracy. With 95%+ STP rate, the extracted data is directly pushed
            into our database
          </Text>
          <div className="flex justify-between items-end mt-4">
            <div>
              <Text
                variant="body"
                fontWeight="font-semibold"
                textColor="text-darkGrey-500"
              >
                Dan McClearly
              </Text>
              <Text
                variant="bodySmall"
                fontWeight="font-normal"
                textColor="text-secondary-400"
              >
                Head of Operations
              </Text>
            </div>
            <span className="relative w-[30px] h-[15px] 2xl:w-[41px] 2xl:h-[24px]">
              <NextImage src="/images/UP.svg" alt="up" layout="fill" />
            </span>
          </div>
          <span className="absolute top-0 left-0">
            <NextImage
              src="/images/Vector.svg"
              alt="vector"
              width="177"
              height="48.5"
            />
          </span>
          <div className="shadow-cardQuotes rounded-[3px] bg-white flex items-center w-fit px-3 py-3 2xl:px-[14px] 2xl:py-[17px] absolute -top-5 -left-4 2xl:-top-6 2xl:-left-6">
            <span className="relative w-[18px] h-[15px] 2xl:w-[24px] 2xl:h-[17.3px]">
              <NextImage
                src="/images/QuotesBlue.svg"
                alt="quotes"
                layout="fill"
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default CardIcon;

import React from 'react';
import Label from './Label';
import PropTypes from 'prop-types';

const Divider = ({ className = '', text }) => {
  return (
    <span
      className={`block bg-secondary-200 h-[1px] relative select-none w-full ${className}`}
    >
      <Label
        htmlFor="divider"
        variant="large"
        textColor="text-secondary-500"
        fontWeight="font-normal"
        fontFamily="font-Poppins"
        className="bg-white absolute -top-[10px] 2xl:-top-[14px] left-1/2 px-2 -translate-x-1/2"
      >
        {text}
      </Label>
    </span>
  );
};
Divider.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};
export default Divider;

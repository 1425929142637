import Heading from 'atoms/Heading';
import PropTypes from 'prop-types';

const timeLineTypes = {
  icon: 'icon',
  dot: 'dot',
};

const TimeLineList = ({ active, desc, visited, onClick, step }) => {
  return (
    <li
      className={`relative flex items-baseline gap-3 pb-6 2xl:pb-[30px] last:pb-3 before:absolute before:h-full before:w-[1px] before:bg-darkGrey-400 before:top-[10px] before:left-1 last:before:h-0 hover:cursor-pointer`}
      onClick={() => onClick(step)}
    >
      <div
        className={`rounded-full relative z-10 ${
          active
            ? 'h-[8px] w-[8px] xl:h-[11px] xl:w-[11px] bg-primary-900 ml-[0.5px] xl:-ml-[0.9px]'
            : 'w-[5px] h-[5px] xl:h-[7px] xl:w-[7px] bg-darkGrey-400 ml-0.5 xl:ml-[1.1px]'
        }`}
      ></div>
      <div>
        <p
          className={`text-xs 2xl:text-sm ${
            active
              ? 'text-primary-900 font-medium'
              : visited
              ? 'text-darkGrey-400'
              : 'text-darkGrey-400'
          }`}
        >
          {desc}
        </p>
      </div>
      <div
        className={`bg-primary-900 rounded-2xl text-white py-1 px-2  text-xxs 2xl:text-xs text-center ${
          active ? 'block' : 'hidden'
        }`}
      >
        {step} of 4
      </div>
    </li>
  );
};

const TimeLine = ({ timeLineListData }) => {
  return (
    <div>
      <Heading
        type="h3"
        className="text-black-800 font-semibold mb-5 2xl:mb-[30px]"
      >
        Quick & Easy Setup
      </Heading>
      <ul>
        {timeLineListData.map(
          ({ active, desc, visited, step, onClick }, index) => (
            <TimeLineList
              key={index}
              active={active}
              desc={desc}
              visited={visited}
              index={index}
              step={step}
              onClick={onClick}
            />
          )
        )}
      </ul>
    </div>
  );
};

TimeLine.propTypes = {
  types: PropTypes.array,
  timeLineListData: PropTypes.array,
};
export default TimeLine;

import PropTypes from 'prop-types';
import IcomoonIcon from './IcomoonIcon';

export const alertType = {
  info: 'info',
  error: 'error',
  success: 'success',
  warning: 'warning',
  infoLight: 'infoLight',
  errorLight: 'errorLight',
  successLight: 'successLight',
  warningLight: 'warningLight',
};

const Alert = ({ children, variant = 'info', alertClass = '' }) => {
  const alertStyle = {
    info: 'bg-info-100 text-white',
    error: 'bg-error-50 text-error-300 ', //afto
    success: 'bg-success-100 text-white',
    warning: 'bg-warning-100 text-black',
    infoLight: 'bg-info-50 text-info-100',
    errorLight: 'bg-error-50 text-error-100',
    successLight: 'bg-success-50 text-success-100',
    warningLight: 'bg-warning-50 text-warning-100',
  };

  return (
    <div
      variant={alertType[variant]}
      className={`flex items-center rounded text-xs 2xl:text-sm px-2.5 py-3 2xl:py-5 ${alertStyle[variant]} ${alertClass}`}
    >
      <span className={`mr-2.5 flex items-center flex-shrink-0`}>
        <IcomoonIcon icon={variant} className="w-4 h-4 xl:w-5 xl:h-5" />
      </span>
      {children}
    </div>
  );
};

Alert.propTypes = {
  variant: PropTypes.string,
  alertClass: PropTypes.string,
  children: PropTypes.string,
};
export default Alert;

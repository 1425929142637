import React from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';
import Text from 'atoms/Text';
const SignUpWith = ({
  className = '',
  imgSrc,
  imgAlt,
  text,
  handleClick = () => {},
}) => {
  return (
    <div
      className={`${className} flex items-center gap-2.5 border border-secondary-300 bg-white hover:bg-darkGrey-300 transition duration-300 rounded-[9px] w-fit p-2 2xl:p-3 cursor-pointer`}
      onClick={handleClick}
    >
      <span className="relative w-3 h-3 xl:w-5 xl:h-5">
        <NextImage src={imgSrc} layout="fill" alt={imgAlt} />
      </span>
      <Text
        variant="bodySmall"
        fontWeight="font-normal"
        textColor="text-black-900"
        fontFamily="font-Poppins"
      >
        {text}
      </Text>
    </div>
  );
};
SignUpWith.propTypes = {
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  text: PropTypes.string,
  handleClick: PropTypes.func,
};
export default SignUpWith;

import Link from 'next/link';
import PropTypes from 'prop-types';
import IcomoonIcon from './IcomoonIcon';

const LinkIcon = ({
  href,
  openInNewTab = false,
  children,
  icon,
  color,
  size,
  className = '',
  disabled,
  classForIcon = 'fill-primary-900 group-hover:fill-primary-800',
  variant = 'body',
  onClick = () => {},
}) => {
  const TextType = {
    body: 'body',
    bodySmall: 'bodySmall',
    caption: 'caption',
  };

  const TextStyle = {
    body: 'text-xs xl:text-sm 2xl:text-base',
    bodySmall: 'text-sm',
    caption: 'text-xs',
  };

  return (
    <div className="group w-fit">
      <div
        variant={TextType[variant]}
        className={`${TextStyle[variant]} w-fit ${
          disabled
            ? 'text-secondary-100 pointer-events-none'
            : 'text-primary-900 group-hover:text-primary-800'
        } font-medium ${icon && 'flex gap-2 items-center'} ${className}`}
        onClick={onClick}
      >
        <Link href={href}>
          {openInNewTab ? (
            <>
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            </>
          ) : (
            children
          )}
        </Link>
        {icon && (
          <IcomoonIcon
            icon={icon}
            color={color}
            size={size}
            className={classForIcon}
          />
        )}
      </div>
    </div>
  );
};

LinkIcon.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.string,
  href: PropTypes.any,
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
};
export default LinkIcon;

import PropTypes from 'prop-types';

const Label = ({
  labelFor = '',
  variant = 'medium',
  textColor = 'text-neutral-900',
  fontWeight = '',
  className = '',
  children,
  fontFamily = '',
  labelDisable = false,
}) => {
  const LabelType = {
    small: 'small',
    medium: 'medium',
    large: 'large',
    extraSmall: 'text-xs',
  };

  const LabelStyle = {
    small: 'text-xs 2xl:text-sm',
    medium: 'text-base',
    large: 'text-sm 2xl:text-lg',
    extraSmall: 'text-xxs xl:text-xs',
  };

  return (
    <label
      htmlFor={`${labelFor}`}
      variant={LabelType[variant]}
      className={`${
        LabelStyle[variant]
      } ${textColor} ${fontWeight} ${fontFamily} ${className} ${
        labelDisable && 'opacity-60 pointer-events-none'
      }`}
    >
      {children}
    </label>
  );
};

Label.propTypes = {
  labelFor: PropTypes.string,
  variant: PropTypes.string,
  textColor: PropTypes.string,
  fontWeight: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
export default Label;

import { alertType } from 'atoms/Alert';
import Button from 'atoms/Button';
import Heading from 'atoms/Heading';
import Text from 'atoms/Text';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import PropTypes from 'prop-types';
import { resendUserOtp } from 'services/user.service';

let timer;
const VerificationCode = ({ email, setAlertState }) => {
  const [otp, setOtp] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState({
    isError: false,
    errorMessage: '',
  });
  const [timesValue, setTimerValue] = useState(60);
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      if (timesValue == 0) {
        return clearTimeout(timer);
      }
      setTimerValue(timesValue - 1);
    }, 1000);
  }, [timesValue]);

  const handleChangeOtp = (value) => {
    // Clear Alert State if Any
    setAlertState({
      showAlert: false,
      alertMessage: '',
    });

    setOtpError({
      isError: false,
      errorMessage: '',
    });

    setOtp(value);
  };

  const handleVerifyEmail = async () => {
    setLoading(true);
    // Clear Alert State if Any
    setAlertState({
      showAlert: false,
      alertMessage: '',
    });

    setOtpError({
      isError: false,
      errorMessage: '',
    });

    signIn('credentials', {
      email,
      otp,
      callbackUrl: '/',
      redirect: false,
    }).then(({ ok, error }) => {
      if (ok) {
        router.push('/');
        setLoading(false);
      } else {
        setOtpError({
          isError: true,
          errorMessage: error,
        });
        setLoading(false);
      }
    });
  };

  const handleResendOtp = async () => {
    // Clear Alert State if Any
    setAlertState({
      showAlert: false,
      alertMessage: '',
    });

    setOtpError({
      isError: false,
      errorMessage: '',
    });

    try {
      const response = await resendUserOtp({ email });
      if (response.status) {
        setAlertState({
          type: alertType.successLight,
          showAlert: true,
          alertMessage: response?.message || 'OTP has been resent successfully',
        });
        setTimerValue(60);
        setOtp('');
      }
    } catch (error) {
      const { response } = error;
      // Clear Alert State if Any
      setAlertState({
        type: alertType.error,
        showAlert: true,
        alertMessage: response?.data?.message,
      });
    }
  };

  return (
    <>
      <Heading
        type="h1"
        className="text-black-800 font-semibold"
        children="Verification code"
      />
      <Text
        variant="body"
        textColor="text-secondary-100"
        fontWeight="font-medium"
        className="mt-2 xl:mt-4"
      >
        Enter the 4 digit verification code sent to
      </Text>
      <Text
        variant="body"
        textColor="text-black-800"
        fontWeight="font-medium"
        className="mt-2"
      >
        {email}
      </Text>
      <div className="mt-6 xl:mt-9">
        <OTPInput
          value={otp}
          onChange={handleChangeOtp}
          numInputs={4}
          placeholder=""
          shouldAutoFocus={true}
          renderSeparator={<span> </span>}
          renderInput={(props) => <input type="number" {...props} />}
          className="w-full"
          containerStyle="flex space-x-4 justify-center"
          inputStyle={`!w-[30px] h-[30px] xl:!w-[40px] xl:!h-[40px] bg-info-200 outline-none !rounded-sm active:border-primary-600 focus:border-primary-600 text-black-800 text-xs xl:text-sm 2xl:text-base ${
            otpError.isError
              ? 'border border-error-100'
              : 'border-2 border-info-100'
          }`}
        />
        {otpError.isError && (
          <Text
            variant="xxs"
            textColor="text-error-100"
            className="mt-2 text-center"
          >
            {otpError.errorMessage}
          </Text>
        )}
      </div>
      <div className="mx-auto text-center mt-4">
        <Text variant="caption" textColor="text-secondary-900" className="">
          00:{timesValue.toString().padStart(2, 0)}s
        </Text>
        <Text
          variant="caption"
          textColor={`${
            timesValue === 0 ? 'text-primary-900' : 'text-blue-100'
          }`}
          fontFamily="font-medium"
          className={`${
            timesValue === 0 ? 'cursor-pointer' : 'cursor-not-allowed'
          } mt-3 mb-5 2xl:mb-9`}
          onClick={handleResendOtp}
        >
          Resend code
        </Text>
        <Button
          id="verifyEmail"
          children={!isLoading && "VERIFY EMAIL"}
          fullWidth={true}
          varient="mediumContained"
          showIcon={false}
          disabled={!(otp.length === 4) || isLoading}
          flex="flex"
          justify="justify-center"
          onClick={handleVerifyEmail}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

VerificationCode.propTypes = {
  email: PropTypes.string,
};
export default VerificationCode;

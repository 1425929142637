import { yupResolver } from '@hookform/resolvers/yup';
import { alertType } from 'atoms/Alert';
import Button from 'atoms/Button';
import Text from 'atoms/Text';
import InputLabel2 from 'molecules/InputLabel2';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { signInUserByEmail } from 'services/user.service';
import * as yup from 'yup';
import VerificationCode from './VerificationCode';
const schema = yup
  .object({
    // email: yup.string().email().required('This field is required'),
    email: yup.string().required('This field is required'), // Added to temporary disable email validation
  })
  .required();

const SignInForm = ({
  className = '',
  showVerification,
  setShowVerification,
  setAlertState,
}) => {
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // Clear Alert State if Any
      setAlertState({
        showAlert: false,
        alertMessage: '',
      });

      const response = await signInUserByEmail(data);
      if (response.status) {
        setAlertState({
          type: alertType.successLight,
          showAlert: true,
          alertMessage: 'OTP sent successfully',
        });
        setShowVerification(true);
      }
    } catch (error) {
      const { response } = error;
      setAlertState({
        type: alertType.error,
        showAlert: true,
        alertMessage: response?.data?.message,
      });
    }
    setLoading(false);
  };

  return (
    <div className={`${className}`}>
      {!showVerification ? (
        <>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <InputLabel2
              type="email"
              id="email"
              inputId="email"
              name="email"
              labelTitle="Email ID"
              placeholder="Enter your email"
              register={register}
              errors={errors}
            />
            <Button
              children={!isLoading && 'SEND CODE TO EMAIL'}
              fullWidth={true}
              varient="mediumContained"
              disabled={!isValid || isLoading}
              showIcon={false}
              flex="flex"
              isLoading={isLoading}
              justify="justify-center"
              btnClass="gap-0.5"
            />
          </form>
          <Text
            variant="body"
            textColor="text-secondary-100"
            fontWeight="font-medium"
            className="mt-4"
          >
            By continuing to Afto, you agree to the
            <span className="text-primary-900">
              {' '}
              <Link href="https://www.docsumo.com/terms">
                <a target="_blank" rel="">
                  Terms and Condition - Docsumo
                </a>
              </Link>{' '}
            </span>{' '}
            and
            <span className="text-primary-900 mx-1">
              <Link href="https://www.getafto.com/privacy-policy">
                <a target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </Link>
            </span>
          </Text>
        </>
      ) : (
        <VerificationCode
          email={getValues('email')}
          setAlertState={setAlertState}
        />
      )}
    </div>
  );
};

SignInForm.propTypes = {
  className: PropTypes.string,
  showVerification: PropTypes.bool,
  setShowVerification: PropTypes.func,
};
export default SignInForm;
